
.primary-text {
  color: var(--v-primary-base);
}

.error-text {
  color: var(--v-error-base);
}

.warning-text {
  color: var(--v-warning-base);
}
